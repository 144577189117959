import ApplicationContext from "../contexts/ApplicationContext";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeaderContainer from "../components/HeaderContainer";
import langHome from "../assets/jsons/language/home.json";
import { useContext } from "react";

export default function HomeScreen() {
  const { applicationContext } = useContext(ApplicationContext);

  return (
    <>
      <HeaderContainer>
        <Header></Header>
        <Banner></Banner>
      </HeaderContainer>

      {/* Presentation Video */}
      <section id="features" className="mt-5">
        <div className="container">
          <div className="row">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MkF_QYKcCdY?si=5br27yaB9RvmDMmI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
      <hr></hr>
      {/* Search event */}
      <section id="features" className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6">
              <img src="images/search-event.png" alt="" />
              <hr style={{ marginTop: "0" }}></hr>
            </div>
            <div className="col-12 col-lg-6 col-md-6 d-flex flex-column my-auto">
              <h2>
                {langHome.SEARCH_EVENT_TITLE[applicationContext.language]}
              </h2>
              <p>{langHome.SEARCH_EVENT_TEXT[applicationContext.language]}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Create event */}
      <section id="features" className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6 d-flex flex-column my-auto">
              <h2>
                {langHome.CREATE_EVENT_TITLE[applicationContext.language]}
              </h2>
              <p>{langHome.CREATE_EVENT_TEXT[applicationContext.language]}</p>
            </div>
            <div className="col-12 col-lg-6 col-md-6">
              <img src="images/create-event.png" alt="" />
              <hr style={{ marginTop: "0" }}></hr>
            </div>
          </div>
        </div>
      </section>

      {/* Share link */}
      <section id="features" className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6">
              <img src="images/features.png" alt="" />
            </div>
            <div className="col-12 col-lg-6 col-md-6 d-flex flex-column my-auto">
              <h2>{langHome.SHARE_LINK_TITLE[applicationContext.language]}</h2>
              <p>{langHome.SHARE_LINK_TEXT[applicationContext.language]}</p>
            </div>
          </div>
        </div>
      </section>

      <div id="plan" className="bg-shade text-center pt-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 col-lg-8 m-auto">
              <h2>
                {langHome.HOME_SUBTITLE_1[applicationContext.language]}{" "}
                <span className="text-bold">
                  {langHome.HOME_SUBTITLE_2[applicationContext.language]}
                </span>
              </h2>
              <p>{langHome.HOME_SUBTITLE_3[applicationContext.language]}</p>
              <img
                className="mt-5 text-center"
                src="images/app-screenshot.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
