import React, { useEffect, useState } from "react";

import { createContext } from "react";
import languages from "../assets/jsons/language/languages.json";

const ApplicationContext = createContext(null);

export const ApplicationContextProvider = ({ children }) => {
  const [applicationContext, setApplicationContext] = useState({});

  useEffect(() => {
    // Device default language
    let auxLanguage = navigator.language || navigator.userLanguage;

    // if the language the user has we don't have it, will select english by default
    if (!languages.availableLanguages.includes(auxLanguage.substring(0, 2))) {
      auxLanguage = "en_US";
    }

    var localLanguage = auxLanguage.substring(0, 2);
    var localLanguageFull = auxLanguage.replace("_", "-");
    var localCountry = localLanguageFull.split("-")[1];

    setApplicationContext({
      language: localLanguage,
      languageFull: localLanguageFull,
      country: localCountry,
    });
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        applicationContext,
        setApplicationContext,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;
