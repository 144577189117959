import { Card, Col, Container, Image, Row, Stack } from "react-bootstrap";

import ApplicationContext from "../contexts/ApplicationContext";
import Header from "../components/Header";
import HeaderContainer from "../components/HeaderContainer";
import langProducts from "../assets/jsons/language/products.json";
import { useContext } from "react";

export default function ProductScreen() {
  const { applicationContext, setApplicationContext } =
    useContext(ApplicationContext);

  return (
    <>
      <HeaderContainer>
        <Header></Header>
      </HeaderContainer>
      <Container className="mt-4">
        <Row className="align-items-center viewport-height py-2">
          <Col md={4}>
            <Card className="p-4">
              <Card.Img variant="top" src="images/app-screenshot.png" />
              <Card.Body>
                <Card.Title>
                  {langProducts.ARENA_MEET_TITLE[applicationContext.language]}
                </Card.Title>
                <Card.Text>
                  {
                    langProducts.ARENA_MEET_SUBTITLE[
                      applicationContext.language
                    ]
                  }
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {langProducts.ARENA_MEET_DESC[applicationContext.language]}
            <br></br>
            <Stack direction="horizontal" gap={1}>
              <a
                href="https://apps.apple.com/ar/app/arena-meet/id6446419312"
                target="_blank"
                className="text-white"
                rel="noreferrer"
              >
                <Image
                  src="/images/AppStoreBadge.svg"
                  style={{ height: "50px", objectFit: "cover" }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.diegobertolini.arena"
                target="_blank"
                className="text-white"
                rel="noreferrer"
              >
                <Image
                  src="/images/GooglePlayBadge.png"
                  style={{ height: "76px", objectFit: "cover" }}
                />
              </a>
            </Stack>
          </Col>
        </Row>

        <Row className="align-items-center viewport-height py-2">
          <Col md={4}>
            <Card className="p-4">
              <Card.Img variant="top" src="/images/coming-soon.png" />
              <Card.Body>
                <Card.Title>
                  {
                    langProducts.ARENA_MEET_MANAGER_TITLE[
                      applicationContext.language
                    ]
                  }
                </Card.Title>
                <Card.Text>
                  {
                    langProducts.ARENA_MEET_MANAGER_SUBTITLE[
                      applicationContext.language
                    ]
                  }
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-left">
            {langProducts.ARENA_MEET_MANAGER_DESC[applicationContext.language]}
          </Col>
        </Row>
      </Container>
    </>
  );
}
