import ApplicationContext from "../contexts/ApplicationContext";
import langFooter from "../assets/jsons/language/footer.json";
import { useContext } from "react";

export default function Footer() {
  const { applicationContext, setApplicationContext } =
    useContext(ApplicationContext);

  return (
    <section id="footer" className="text-white text-center">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-3">
              {langFooter.FOOTER_TITLE_1[applicationContext.language]}{" "}
              <span className="text-bold">
                {langFooter.FOOTER_TITLE_2[applicationContext.language]}
              </span>
            </h2>
            <p>{langFooter.FOOTER_SUBTITLE[applicationContext.language]}</p>
            <a
              className="get-app btn m-2"
              href="https://apps.apple.com/ar/app/arena-meet/id6446419312"
              target="_blank"
              rel="noreferrer"
            >
              {langFooter.GET_FOR_IPHONE[applicationContext.language]}
            </a>
            <a
              className="get-app btn m-2"
              href="https://play.google.com/store/apps/details?id=com.diegobertolini.arena"
              target="_blank"
              rel="noreferrer"
            >
              {langFooter.GET_FOR_ANDROID[applicationContext.language]}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
