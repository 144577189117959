import { doc, setDoc } from "firebase/firestore";

import { FeedbacksCollection } from "./firestoreCollections";
import { firebaseFirestore } from "../App";
import { v4 as uuid } from "uuid";

export const addFeedback = async (data) => {
  try {
    const feedbackId = uuid();
    await setDoc(doc(firebaseFirestore, FeedbacksCollection, feedbackId), {
      ...data,
      id: feedbackId,
    });
  } catch (err) {
    console.error("Error saving feedback", err);
  }
};
