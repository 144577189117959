import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import ErrorScreen from "./screens/ErrorScreen";
import HelpScreen from "./screens/HelpScreen";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseFunctions = getFunctions(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);

if (process.env.REACT_APP_NODE_ENV === "development") {
  // Connects Functions Emulator
  connectFunctionsEmulator(
    firebaseFunctions,
    process.env.REACT_APP_EMULATOR_FUNCTIONS_SERVER,
    process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT
  );

  // Connects Firestore Emulator
  connectFirestoreEmulator(
    firebaseFirestore,
    process.env.REACT_APP_EMULATOR_FIRESTORE_SERVER,
    process.env.REACT_APP_EMULATOR_FIRESTORE_PORT
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "/signup",
    element: <HomeScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "product",
    element: <ProductScreen />,
  },
  {
    path: "help",
    element: <HelpScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
export { firebaseApp, firebaseFunctions, firebaseFirestore };
