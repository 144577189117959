import ApplicationContext from "../contexts/ApplicationContext";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import langHeader from "../assets/jsons/language/header.json";
import { useContext } from "react";

export default function Header() {
  const { applicationContext, setApplicationContext } =
    useContext(ApplicationContext);

  return (
    <Navbar expand="lg" className="pt-3" variant="dark">
      <Container fluid>
        <Navbar.Toggle aria-controls={"offcanvasNavbar-expand-lg"} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <ul className="navbar-nav text-center">
              <li className="nav-item">
                <Link to={`/`} className="nav-link active">
                  {langHeader.HOME_TITLE[applicationContext.language]}
                </Link>
              </li>
              <li key={"key2"} className="nav-item text-center">
                <Link to={`/product`} className="nav-link active">
                  {langHeader.PRODUCT_TITLE[applicationContext.language]}
                </Link>
              </li>
              <li className="nav-item text-center">
                <Link to={`/help`} className="nav-link active">
                  {langHeader.HELP_TITLE[applicationContext.language]}
                </Link>
              </li>
              {/* <li className="nav-item text-center">
                <NavDropdown title={langHeader.LANGUAGE[applicationContext.language]} id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <Link href="" locale="en">
                    {langHeader.LANGUAGE_ENGLISH[applicationContext.language]}
                      
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link href="" locale="es">
                    {langHeader.LANGUAGE_SPANISH[applicationContext.language]}
                      
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link href="" locale="pt">
                    {langHeader.LANGUAGE_PORTUGUESE[applicationContext.language]}
                      
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </li> */}
              <li className="nav-item text-center mb-2">
                <a
                  className="get-app btn"
                  href="https://apps.apple.com/ar/app/arena-meet/id6446419312"
                  target="_blank"
                  rel="noreferrer"
                >
                  {langHeader.GET_FOR_IPHONE[applicationContext.language]}
                </a>
              </li>
              <li className="nav-item text-center">
                <a
                  className="get-app btn"
                  href="https://play.google.com/store/apps/details?id=com.diegobertolini.arena"
                  target="_blank"
                  rel="noreferrer"
                >
                  {langHeader.GET_FOR_ANDROID[applicationContext.language]}
                </a>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
