import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Tab,
  Tabs,
  Toast,
} from "react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";

import ApplicationContext from "../contexts/ApplicationContext";
import Header from "../components/Header";
import HeaderContainer from "../components/HeaderContainer";
import ToastContainer from "react-bootstrap/ToastContainer";
import { addFeedback } from "../services/feedbacks";
import feedbackTypes from "../assets/jsons/feedbackTypes.json";
import { firebaseFunctions } from "../App";
import { httpsCallable } from "firebase/functions";
import langHelp from "../assets/jsons/language/help.json";

export default function HelpScreen() {
  const { applicationContext } = useContext(ApplicationContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("1");
  const [comment, setComment] = useState("");
  const [feedbackSavedSuccess, setFeedbackSavedSuccess] = useState(false);
  const [feedbackSavedError, setFeedbackSavedError] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const subimtFeedback = async () => {
    try {
      const data = { name, email, reason, comment };
      addFeedback(data);
      httpsCallable(firebaseFunctions, "utils-sendEmail")(data);
      setFeedbackSavedSuccess(true);
      clearForm();
    } catch (err) {
      setFeedbackSavedError(true);
      console.error("Error saving feedback", err);
    }
  };

  const clearForm = useCallback(() => {
    setName("");
    setEmail("");
    setReason("1");
    setComment("");
  }, [setName, setEmail, setReason, setComment]);

  const validateForm = useCallback(() => {
    let validation = true;

    if (name.trim().length === 0) {
      validation = false;
    }

    if (
      email.trim().length === 0 ||
      // eslint-disable-next-line no-useless-escape
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      validation = false;
    }

    if (reason.trim().length === 0) {
      validation = false;
    }

    if (comment.trim().length === 0) {
      validation = false;
    }

    setSubmitEnabled(validation);
  }, [name, email, reason, comment]);

  useEffect(() => {
    validateForm();
  }, [name, email, reason, comment, validateForm]);

  return (
    <>
      <HeaderContainer>
        <Header></Header>
      </HeaderContainer>
      <Container className="mt-4">
        <Tabs defaultActiveKey="faq" className="mb-3">
          <Tab
            eventKey="faq"
            title={langHelp.FAQ[applicationContext.language] ?? "FAQ"}
          >
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header as="h5">
                  {langHelp.HELP_EVENT_CREATE[applicationContext.language]}
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/MdHht0BVBLM?si=SCJRAF8NjhDp-RWp"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p>
                    {langHelp.HELP_EVENT_CREATE_1[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/new-event/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {langHelp.HELP_EVENT_CREATE_2[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/new-event/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {langHelp.HELP_EVENT_CREATE_3[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/new-event/3.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {langHelp.HELP_EVENT_CREATE_4[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/new-event/4.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header as="h5">
                  {langHelp.HELP_EVENT_LINK_CREATE[applicationContext.language]}
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/o0dh6rrQBaY?si=QUEqzHBcxpygx6Y9"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p>
                    {
                      langHelp.HELP_EVENT_LINK_CREATE_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/share-link/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_LINK_CREATE_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/share-link/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_LINK_CREATE_3[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/share-link/3.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_LINK_CREATE_4[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/share-link/4.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header as="h5">
                  {langHelp.HELP_EVENT_JOIN[applicationContext.language]}
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <p>
                    {langHelp.HELP_EVENT_JOIN_1[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/scan-qr/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {langHelp.HELP_EVENT_JOIN_2[applicationContext.language]}
                  </p>
                  <Image
                    src="/images/help/scan-qr/2.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header as="h5">
                  {langHelp.HELP_EVENT_JOIN_MAP[applicationContext.language]}
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/wPK4Lu8SKAs?si=uHir4CzHcsVm3ygg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_3[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event/3.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_4[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event/4.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header as="h5">
                  {
                    langHelp.HELP_EVENT_JOIN_MAP_CHAT[
                      applicationContext.language
                    ]
                  }
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_CHAT_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event-chat/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_JOIN_MAP_CHAT_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/find-event-chat/2.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header as="h5">
                  {langHelp.HELP_EVENT_ASSISTANCE[applicationContext.language]}
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-select/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-select/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_3[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-select/3.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header as="h5">
                  {
                    langHelp.HELP_EVENT_ASSISTANCE_VIEW[
                      applicationContext.language
                    ]
                  }
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_VIEW_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-review/1.png"
                    style={{ height: "500px" }}
                  />
                  {/* <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_VIEW_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-review/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_VIEW_3[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/assistance-review/3.png"
                    style={{ height: "500px" }}
                  /> */}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header as="h5">
                  {
                    langHelp.HELP_EVENT_ASSISTANCE_ADD_USER[
                      applicationContext.language
                    ]
                  }
                </Accordion.Header>
                <Accordion.Body className="text-center">
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_ADD_USER_1[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/add-non-user/1.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_ADD_USER_2[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/add-non-user/2.png"
                    style={{ height: "500px" }}
                  />
                  <p>
                    {
                      langHelp.HELP_EVENT_ASSISTANCE_ADD_USER_3[
                        applicationContext.language
                      ]
                    }
                  </p>
                  <Image
                    src="/images/help/add-non-user/3.png"
                    style={{ height: "500px" }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Tab>
          <Tab
            eventKey="feedback"
            title={langHelp.CONTACT[applicationContext.language] ?? "Contact"}
          >
            <Form style={{ textAlign: "left" }}>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    {langHelp.NAME[applicationContext.language]}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    {langHelp.EMAIL[applicationContext.language]}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    {langHelp.REASON[applicationContext.language]}
                  </Form.Label>
                  <Form.Select
                    defaultValue={langHelp.SELECT[applicationContext.language]}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    {feedbackTypes &&
                      feedbackTypes.map((f) => (
                        <option key={f.id} value={f.id}>
                          {f.name[applicationContext.language]}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    {langHelp.COMMENT[applicationContext.language]}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Button
                onClick={() => subimtFeedback()}
                variant="primary"
                className="mt-4"
                disabled={!submitEnabled}
              >
                {langHelp.SEND[applicationContext.language]}
              </Button>
            </Form>
          </Tab>
        </Tabs>
        <ToastContainer position={"bottom-center"}>
          <Toast
            onClose={() => setFeedbackSavedSuccess(false)}
            show={feedbackSavedSuccess}
            delay={3000}
            autohide
            bg={"success"}
          >
            <Toast.Body className="text-white">
              {langHelp.FEEDBACK_OK[applicationContext.language]}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <ToastContainer position={"bottom-center"}>
          <Toast
            onClose={() => setFeedbackSavedError(false)}
            show={feedbackSavedError}
            delay={3000}
            autohide
            bg={"danger"}
          >
            <Toast.Body className="text-white">
              {langHelp.FEEDBACK_ERROR[applicationContext.language]}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Container>
    </>
  );
}
