import ApplicationContext from "../contexts/ApplicationContext";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import langBanner from "../assets/jsons/language/banner.json";
import { useContext } from "react";

export default function Banner() {
  const { applicationContext } = useContext(ApplicationContext);

  return (
    <div className="row ml-0 mt-5">
      <div className="col-12 col-lg-12 col-md-12">
        <img width="100px" height="100px" src="images/logo.png" alt="" />
      </div>
      <div
        className="col-12 col-lg-8 col-md-8 hero-left"
        style={{ paddingTop: "20px" }}
      >
        <h1>
          <span className="text-bold">Arena Meet</span>
        </h1>
        <span className="text-bold">
          {langBanner.HOME_SUBTITLE[applicationContext.language]}
        </span>
        <br />
        <br />
        <p className="w-75 mb-5">
          {langBanner.HOME_BANNER_TEXT[applicationContext.language]}
        </p>
        <Stack direction="horizontal" gap={1}>
          <a
            href="https://apps.apple.com/ar/app/arena-meet/id6446419312"
            target="_blank"
            className="text-white"
            rel="noreferrer"
          >
            <Image
              src="/images/AppStoreBadge.svg"
              style={{ height: "50px", objectFit: "cover" }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.diegobertolini.arena"
            target="_blank"
            className="text-white"
            rel="noreferrer"
          >
            <Image
              src="/images/GooglePlayBadge.png"
              style={{ height: "76px", objectFit: "cover" }}
            />
          </a>
        </Stack>
      </div>

      <div
        className="col-4 col-lg-4 col-md-4 ml-auto d-flex"
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <img
          alt=""
          src="images/hero-right-image.png"
          className="hero-right-img"
          style={{ maxHeight: "500px" }}
        />
      </div>
    </div>
  );
}
